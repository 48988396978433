/* globals window */

import superouter from './superouter'
import stream from './mithril/stream/stream'

// The internal recursive subRoute instance generator
// called internally via `subroute.subRoute()`
// the `$parent` and `Parent` are threaded through from the initial
// call.
function SubRoute(
	$root,
	Root,
	Parent = null,
	typeName,
	defaultRoute,
	cases,
	{ pushState = () => {}, replaceState = () => {}, ...defaultOptions },
) {
	let affixesCache
	function _affixes() {
		if (Parent == null) {
			return {
				suffix: $root.value.args(),
				prefix: '',
			}
		} else {
			const {
				value: { args },
			} = Parent.get()
			const suffix = args

			const rootHREF = Root.toURL($root())

			const i = rootHREF.lastIndexOf(suffix)
			const prefix = rootHREF.substring(0, i)

			return { suffix, prefix }
		}
	}

	function affixes() {
		if (affixesCache) {
			return affixesCache
		} else {
			return (affixesCache = _affixes())
		}
	}

	affixes()

	const Type = superouter.type(typeName, cases)

	function fromURL(theirURL) {
		const { prefix } = affixes()
		const url = theirURL.replace(prefix, '')

		const x = ('/' + url).replace(/\/\//g, '/')

		const match = Type.matchOr(() => defaultRoute(Type), x)

		return match
	}

	function asRootRoute(x) {
		const { prefix } = affixes()
		return Root.matchOr(
			() => null,
			(prefix + Type.toURL(x)).replace(/\/\//g, '/'),
		)
	}

	function subroute(typeName, defaultRoute, cases, options = {}) {
		return SubRoute($root, Root, out, typeName, defaultRoute, cases, {
			pushState,
			replaceState,
			...defaultOptions,
			...options,
		})
	}

	function set(request, theirOptions = {}) {
		const options = { ...defaultOptions, ...theirOptions }

		const route = typeof request == 'function' ? request(get()) : request
		const rootRoute = asRootRoute(route)

		$root(rootRoute)

		if (options.replace) {
			replaceState(toURL())
		} else {
			pushState(toURL())
		}
		return route
	}

	function replace(request, theirOptions) {
		return set(request, { replace: true, ...theirOptions })
	}

	function setValue(request, theirOptions) {
		const got = get()
		const gotValue = got.value || {}
		const routeValue =
			typeof request == 'function' ? request(gotValue) : request
		const route = { ...got, value: routeValue }

		return set(route, theirOptions)
	}

	function replaceValue(request, theirOptions) {
		return setValue(request, { replace: true, ...theirOptions })
	}

	function get() {
		return fromURL(Root.toURL($root()))
	}

	function getValue() {
		return get().value || {}
	}

	// m.route.get()
	function toURL(...args) {
		if (args.length == 0) {
			return Root.toURL($root())
		} else {
			return Root.toURL(asRootRoute(args[0]))
		}
	}

	function link(route, theirOptions) {
		const href = toURL(route)
		return {
			href,
			onclick(e = EventMock) {
				e.preventDefault()
				set(route, theirOptions)
			},
		}
	}

	const normalize = x => x.split('/').filter(Boolean).join('/')
	let $stream = stream()
	$root.$stream().map(() => {
		const { prefix: a } = affixesCache
		const { prefix: b } = _affixes()
		const f = normalize

		if (f(a) == f(b)) {
			$stream(get())
		}
		return null
	})

	const out = {
		affixes,
		fromURL,
		asRootRoute,
		subroute,
		toURL,
		get,
		getValue,
		set,
		setValue,
		link,
		$stream: () => $stream,
		replace,
		replaceValue,
	}

	Object.assign(out, Type, {
		toURL,
		fromURL,
	})

	return out
}

const WindowMock = {
	history: {
		pushState(_, __, href) {
			WindowMock.location.pathname = href
		},
		replaceState(_, __, href) {
			WindowMock.location.pathname = href
		},
	},
	addEventListener() {},
	location: { pathname: '' },
}

const EventMock = {
	preventDefault() {},
}

// Exposes the subroute interface but for the parent route
// so that even the parent route has the same interface to ensure
// consistency
function RootRoute({
	$,
	name: typeName,
	defaultRoute,
	routes: cases,
	window: theirWindow = typeof window != 'undefined' ? window : WindowMock,
	pathname: getPathName = () => theirWindow.location.pathname,
	redraw = () => {},
	onpopstate = f =>
		theirWindow.addEventListener('popstate', e => {
			f(e)
			redraw()
		}),
	pushState = href => theirWindow.history.pushState('', {}, href),
	replaceState = href => theirWindow.history.replaceState('', {}, href),
	...defaultOptions
}) {
	const Type = superouter.type(typeName, cases)

	const subRoute = SubRoute($, Type, null, typeName, defaultRoute, cases, {
		pushState,
		replaceState,
		isRootRoute: true,
		...defaultOptions,
	})

	function refresh() {
		let defaulted
		$(Type.matchOr(() => (defaulted = defaultRoute(Type)), getPathName()))
		if (defaulted) {
			subRoute.set(subRoute.get())
		}
	}

	// when popstate happens refresh state tree route
	onpopstate(refresh)

	// write to state tree initial route state
	refresh()

	return subRoute
}

export default { Root: RootRoute, WindowMock }
