'use strict'

function Vnode(tag, key, attrs, children, text, dom) {
	return {
		tag: tag,
		key: key,
		attrs: attrs,
		children: children,
		text: text,
		dom: dom,
		domSize: undefined,
		state: undefined,
		events: undefined,
		instance: undefined,
	}
}
Vnode.normalize = function (node) {
	if (Array.isArray(node))
		return Vnode(
			'[',
			undefined,
			undefined,
			Vnode.normalizeChildren(node),
			undefined,
			undefined,
		)
	if (node == null || typeof node === 'boolean') return null
	if (typeof node === 'object') return node
	return Vnode('#', undefined, undefined, String(node), undefined, undefined)
}
Vnode.normalizeChildren = function (input) {
	let children = []
	if (input.length) {
		let isKeyed = input[0] != null && input[0].key != null
		// Note: this is a *very* perf-sensitive check.
		// Fun fact: merging the loop like this is somehow faster than splitting
		// it, noticeably so.
		for (var i = 1; i < input.length; i++) {
			if ((input[i] != null && input[i].key != null) !== isKeyed) {
				throw new TypeError(
					isKeyed && (input[i] != null || typeof input[i] === 'boolean')
						? 'In fragments, vnodes must either all have keys or none have keys. You may wish to consider using an explicit keyed empty fragment, m.fragment({key: ...}), instead of a hole.'
						: 'In fragments, vnodes must either all have keys or none have keys.',
				)
			}
		}
		for (var i = 0; i < input.length; i++) {
			children[i] = Vnode.normalize(input[i])
		}
	}
	return children
}

export default Vnode
