'use strict'

import hyperscript from './hyperscript'
import request from './request.js'
import mountRedraw from './mount-redraw'

import render from './render.js'
import vnode from './render/vnode'

function m() {
	return hyperscript.apply(this, arguments)
}
m.m = hyperscript
m.trust = hyperscript.trust
m.fragment = hyperscript.fragment
m.mount = mountRedraw.mount
m.render = render
m.redraw = mountRedraw.redraw
m.request = request.request
m.vnode = vnode

export default m
