/* globals document, window */
import router from './router.js'
import * as z from './z/index.js'
import css from './css/index.js'
import stream from './mithril/stream/stream.js'
import h from './hyperscript.js'
import localStorage from './localStorage'
import { v4 as id } from 'uuid'
import DB from './db'

function service(name, dependencies, visitor) {
	name
	const x = stream.merge(dependencies)
	let running = false
	let scheduled = []
	let last
	async function run(deps) {
		if (!running) {
			running = true
			last = await visitor(...deps)
			running = false

			while (scheduled.length) {
				let x = scheduled.shift()
				last = await run(x)
			}
		} else {
			scheduled.push(deps)
		}
		return last
	}
	return x.map(run)
}

function start({
	initial = {},
	namespace = null,
	routes: routeAndComps = {
		Home: ['/', () => null],
	},
	defaultRender = null,
	defaultRoute = x => x.Home(),
	services = ({ ...args }) => () => ({ ...args }),
	render: theirRender = ({ currentRender, attrs }) =>
		currentRender
			? currentRender(attrs)
			: defaultRender && defaultRender(attrs),

	container = document.body,
} = {}) {
	if (namespace) {
		localStorage.setNamespace('bute.v1')
	}

	const defaultInitial = {
		session_id: localStorage.getItemOrSet('session_id', id()),
		device_id: localStorage.getItemOrSet('device_id', id()),
	}
	const $ = z.Z({ initial: { ...defaultInitial, ...initial } }).$

	const routes = Object.fromEntries(
		Object.entries(routeAndComps).map(([k, [a, _]]) => [k, a]),
	)

	const comps = Object.fromEntries(
		Object.entries(routeAndComps).map(([k, [_, b]]) => [k, b]),
	)

	const Route = router.Root({
		$: $.route,
		name: 'Root',
		defaultRoute,
		routes,
		redraw: h.redraw,
	})

	$.route.$stream().map(() => h.redraw())

	const RouteComponent = Route.fold(comps)

	let currentRender = defaultRender
	let prevRender = null
	let currentAttrs = { $, Route }
	let prevRoute = $.route()
	let loadingRouteComp = false

	const onRouteChange = services(currentAttrs) || (attrs => attrs)
	async function loadRouteComp() {
		if (!loadingRouteComp) {
			loadingRouteComp = true
			let result = RouteComponent(Route.get())
			let render
			if ('then' in result) {
				render = await result
			} else {
				render = result
			}
			if ('default' in render) {
				render = render.default
			} else if ('Main' in render) {
				let Main = render.Main
				render = x => m(Main, x)
			} else if ('main' in render) {
				let main = render.main
				function Main({ attrs }) {
					const view = main(attrs)
					return { view }
				}
				render = x => h(Main, x)
			}
			currentAttrs = await onRouteChange({
				renderer: render,
				...currentAttrs,
			})
			prevRender = currentRender
			currentRender = render
			loadingRouteComp = false
			h.redraw()
		}
	}
	loadRouteComp()

	h.mount(container, function () {
		function view() {
			let currentRoute = $.route.tag()
			if (currentRoute != prevRoute) {
				loadRouteComp()
			}
			prevRoute = currentRoute
			return theirRender({
				attrs: currentAttrs,
				currentRender,
				prevRender,
				currentRoute,
				prevRoute,
			})
		}
		return { view }
	})

	return currentAttrs
}

const request = h.request

function sql(strings, ...values) {
	let session_id = localStorage.getItem('how.session_id')
	if (session_id == 'null') {
		session_id = null
	}
	let device_id = localStorage.getItem('how.device_id')
	if (device_id == 'null') {
		device_id = null
	}
	return request({
		method: 'POST',
		url: '/api/sql',
		body: { strings, values, session_id },
	}).then(({ type, data }) => {
		if (type == 'data') {
			return data
		} else if (type == 'error') {
			const err = new Error(data.message)
			err.stack = data.stack
			throw err
		} else {
			throw new Error('Internal Server Error')
		}
	})
}

export { start, h, sql, stream, css, service, request, DB, id, localStorage }

export default h
