import h, * as H from './lib/how/index.js'

// H.localStorage.stream.map(x => {
// 	console.log(x)
// })

H.start({
	services(attrs) {
		window.H = H

		import('./services/device').then(x => {
			console.log(x.default(H, attrs))
		})

		return x => x
	},
	routes: {
		Home: ['/', () => import('./pages/home2.js')],
		Wow: [
			'/wow',
			() => () =>
				h(
					'ion-app',
					{ id: 'app' },
					h('ion-header', h('ion-toolbar', h('ion-title', 'Bute Wow'))),
					h('ion-content.ion-padding', h('h1', 'Bute Wow')),
				),
		],
		CSSTest: ['/css-test', () => import('./pages/css-test')],
		IonicPlayground: [
			'/ionic-playground',
			() => import('./pages/ionic-playground.js'),
		],
		FontTest: ['/font-test', () => import('./pages/font-test')],
		LoanCalc: ['/loan-calculator', () => import('./pages/loan-calculator')],
	},
})
