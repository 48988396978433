/* globals requestAnimationFrame */
import render from './render.js'

import mountRedraw from './api/mount-redraw'

export default mountRedraw(
	render,
	typeof requestAnimationFrame !== 'undefined' ? requestAnimationFrame : null,
	typeof console !== 'undefined' ? console : null,
)
