import * as S from './stags/index.js'

const Valid = {
	name: 'Valid',
	type: 'Valid',
	specs: {},
	tags: ['Y', 'N'],
	Y: value => ({ case: 'Y', tag: 'Y', type: 'Valid', value }),
	N: value => ({ case: 'N', tag: 'N', type: 'Valid', value }),
	bifold: (N, Y) => o =>
		({
			Y,
			N,
		}[o.tag](o.value)),
	fold: ({ Y, N }) => o => ({ Y, N }[o.tag](o.value)),
	map: f => o =>
		({
			Y: x => Valid.Y(f(x)),
			N: () => o,
		}[o.tag](o.value)),
}

const PatternToken = {
	name: 'PatternToken',
	type: 'PatternToken',
	specs: {},
	tags: ['Path', 'Part'],
	Path: value => ({ case: 'Path', tag: 'Path', value, type: 'PatternToken' }),
	Part: value => ({ case: 'Part', tag: 'Part', value, type: 'PatternToken' }),

	groupSpecificity: tokens =>
		tokens.map(URLToken.specificity).reduce((p, n) => p + n, 0),

	fold: ({ Path, Part }) => o =>
		({
			Path,
			Part,
		}[o.tag](o.value)),

	infer: segment =>
		segment.startsWith(':')
			? PatternToken.Part(segment.slice(1))
			: PatternToken.Path(segment),

	groupValidations: {
		duplicateDef: allTokensPairs => {
			// Pair (CaseName, PatternStr)
			const patterns = allTokensPairs.map(([k, v]) => [
				k,
				PatternToken.toPattern(v),
			])

			// StrMap (PatternStr, CaseName[])
			const patternStrDupeSearch = patterns.reduce((p, [caseName, pattern]) => {
				p[pattern] = p[pattern] || []
				p[pattern].push(caseName)

				return p
			}, {})

			// StrMap (CaseName, DupeMetaData)
			// where
			// DupeMetaData =
			//  { caseNames::CaseName[], patternStr::PatternStr }
			const caseDupes = Object.entries(patternStrDupeSearch).reduce(
				(p, [patternStr, caseNames]) => {
					if (caseNames.length > 1) {
						caseNames.forEach(caseName => {
							p[caseName] = {
								caseNames,
								patternStr,
							}
						})
					}
					return p
				},
				{},
			)

			if (Object.keys(caseDupes).length) {
				return Valid.N(
					// StrMap (CaseName, PatternToken.Error )
					Object.entries(caseDupes).map(
						([caseName, { caseNames, patternStr }]) => [
							caseName,
							PatternToken.Error.DuplicateDef({
								caseNames,
								patternStr,
							}),
						],
					),
				)
			} else {
				return Valid.Y(allTokensPairs)
			}
		},
	},

	singleValidations: {
		duplicatePart: tokens => {
			const dupeParts = [
				tokens
					.flatMap(
						PatternToken.fold({
							Path: () => [],
							Part: x => [x],
						}),
					)
					.reduce((p, n) => {
						p[n] = p[n] || 0
						p[n] = p[n] + 1
						return p
					}, {}),
			].flatMap(o => Object.entries(o).flatMap(([k, v]) => (v > 1 ? [k] : [])))

			if (dupeParts.length) {
				return Valid.N(
					PatternToken.Error.DuplicatePart({
						dupeParts,
					}),
				)
			} else {
				return Valid.Y(tokens)
			}
		},
	},

	Error: {
		name: 'PatternToken.Error',
		type: 'PatternToken.Error',
		tags: ['DuplicateDef', 'DuplicatePart'],
		specs: {},

		DuplicateDef({ caseNames, patternStr }) {
			return {
				type: 'PatternToken.Error',
				case: 'DuplicateDef',
				tag: 'DuplicateDef',
				value: new TypeError(
					'Found duplicate pattern definitions for ' +
						'routes: ' +
						caseNames.join(', ') +
						'.  ' +
						'They all have equivalent pattern strings: ' +
						patternStr +
						'.  ' +
						'Duplicated patterns lead to ambiguous matches.',
				),
			}
		},

		DuplicatePart({ dupeParts }) {
			return {
				type: 'PatternToken.Error',
				case: 'DuplicatePart',
				tag: 'DuplicatePart',
				value: new TypeError(
					'Found duplicate variable bindings: ' +
						dupeParts.join(', ') +
						'.  Duplicated names lead to ambiguous bindings.',
				),
			}
		},
	},

	validate(tokens) {
		const out = Object.values(PatternToken.singleValidations).map(f =>
			f(tokens),
		)

		const invalids = out.filter(x => x.tag === 'N').map(x => x.value)

		return invalids.length > 0 ? Valid.N(invalids) : Valid.Y(tokens)
	},

	validateGroup(allTokensGroup) {
		const validTokensGroup = allTokensGroup.filter(([, v]) => v.tag === 'Y')

		const out = Object.values(PatternToken.groupValidations).map(f =>
			f(validTokensGroup.map(([k, v]) => [k, v.value])),
		)

		const invalids = out.filter(x => x.tag === 'N').map(x => x.value)

		return invalids.length > 0 ? Valid.N(invalids) : Valid.Y(allTokensGroup)
	},

	toString: x =>
		({
			Path: x => x,
			Part: x => ':' + x,
		}[x.tag](x.value)),

	toPattern: xs => xs.map(PatternToken.toString).join('/'),
}

const URLToken = {
	Path: value => ({ case: 'Path', tag: 'Path', value, type: 'URLToken' }),
	Part: ({ key, value }) => ({
		case: 'Part',
		tag: 'Part',
		value: { key, value },
		type: 'URLToken',
	}),
	Variadic: ({ key, value }) => ({
		case: 'Variadic',
		tag: 'Variadic',
		value: { key, value },
		type: 'URLToken',
	}),
	Unmatched: ({ expected, actual }) => ({
		case: 'Unmatched',
		tag: 'Unmatched',
		value: { expected, actual },
		type: 'URLToken',
	}),

	specificity: token =>
		URLToken.fold({
			Path: () => 0b100,
			Part: () => 0b010,
			Variadic: () => 0b001,
			Unmatched:
				// We only sort matches
				/* istanbul ignore next */
				() => 0b000,
		})(token),

	tags: ['Path', 'Part', 'Variadic', 'Unmatched'],
	specs: {},
	fold: ({ Path, Part, Variadic, Unmatched }) => x =>
		({
			Path,
			Part,
			Variadic,
			Unmatched,
		}[x.tag](x.value)),

	toString: x =>
		URLToken.fold({
			Path: x => x,
			Part: ({ value: x, args = '' }) => x + args,
			Variadic: ({ value: x }) => x,
			Unmatched: ({ actual: x }) => x,
		})(x),

	toURL: xs =>
		('/' + xs.map(URLToken.toString).join('/'))
			.split('/')
			.filter(Boolean)
			.join('/'),

	toArgs: xs =>
		xs.reduce(
			(p, n) =>
				URLToken.fold({
					Part: ({ key, value }) => Object.assign(p, { [key]: value }),
					Path: () => p,
					Unmatched: () => p,
					Variadic: ({ key, value }) => Object.assign(p, { [key]: value }),
				})(n),
			{},
		),

	fromPattern: o => segment =>
		PatternToken.fold({
			Path: expected =>
				segment === expected
					? URLToken.Path(segment)
					: URLToken.Unmatched({ expected, actual: segment }),
			Part: key => URLToken.Part({ key, value: segment }),
		})(o),

	validations: {
		excessPatterns: patternTokens => urlTokens => {
			const numSegments = urlTokens.length

			const numPatterns = patternTokens.length

			const excessPatterns =
				numPatterns > numSegments ? patternTokens.slice(numSegments) : []

			if (excessPatterns.length) {
				return Valid.N(
					URLToken.Error.ExcessPattern({
						urlTokens,
						patternTokens,
						excessPatterns,
					}),
				)
			} else {
				return Valid.Y(urlTokens)
			}
		},

		unmatchedPaths: patternTokens => urlTokens => {
			const unmatched = urlTokens.filter(x => x.tag === 'Unmatched')

			if (unmatched.length) {
				return Valid.N(
					URLToken.Error.UnmatchedPaths({ patternTokens, urlTokens }),
				)
			} else {
				return Valid.Y(urlTokens)
			}
		},
	},

	Error: {
		UnmatchedPaths({ patternTokens, urlTokens }) {
			return {
				type: 'URLToken.Error',
				case: 'UnmatchedPaths',
				tag: 'UnmatchedPaths',
				value: new TypeError(
					'Pattern ' +
						PatternToken.toPattern(patternTokens) +
						' could not match URL ' +
						URLToken.toURL(urlTokens) +
						' due to unmatched path segments: ' +
						urlTokens
							.map(x => (x.tag === 'Unmatched' ? URLToken.toString(x) : '...'))
							.join('/'),
				),
			}
		},

		ExcessPattern({ urlTokens, excessPatterns, patternTokens }) {
			return {
				type: 'URLToken.Error',
				case: 'ExcessPattern',
				tag: 'ExcessPattern',
				value: new TypeError(
					'The URL ' +
						URLToken.toURL(urlTokens) +
						' had excess patterns (' +
						PatternToken.toPattern(excessPatterns) +
						')' +
						' when parsed as part of pattern:' +
						' ' +
						PatternToken.toPattern(patternTokens),
				),
			}
		},
	},

	validate(patternTokens, urlTokens) {
		const out = Object.values(URLToken.validations).map(f =>
			f(patternTokens)(urlTokens),
		)

		const invalids = out.filter(x => x.tag === 'N').map(x => x.value)

		return invalids.length > 0 ? Valid.N(invalids) : Valid.Y(urlTokens)
	},
}

function tokenizePattern(pattern) {
	const patternTokens = pattern.split('/').map(PatternToken.infer)

	return [patternTokens].map(PatternToken.validate).shift()
}

function tokenizeURL(patternTokens, theirURL) {
	const url = theirURL

	const urlTokens = url
		.split('/')
		.slice(0, patternTokens.length)
		.map((segment, i) => URLToken.fromPattern(patternTokens[i])(segment))

	const segments = url.split('/')

	const numSegments = segments.length

	const numPatterns = patternTokens.length

	const excessSegments =
		numSegments > numPatterns ? segments.slice(numPatterns) : []

	const args = excessSegments

	const completeTokens = urlTokens

	return [URLToken.validate(patternTokens, completeTokens)]
		.map(
			Valid.map(() =>
				urlTokens.concat(
					URLToken.Variadic({
						key: 'args',
						value: args.join('/'),
					}),
				),
			),
		)
		.shift()
}

function routeValidator({ tokenized }) {
	const groupInvalids = [
		PatternToken.validateGroup(Object.entries(tokenized)),
	].flatMap(
		Valid.bifold(
			x => x,
			() => [],
		),
	)

	// Pair (CaseName, PatternToken.Error[])
	const invalids = Object.entries(tokenized)
		.filter(([, tokens]) => tokens.tag === 'N')
		.map(([key, x]) => [key, x.value])
		.concat(groupInvalids.reduce((p, n) => p.concat(n), []))

	if (invalids.length) {
		return Valid.N(
			Object.entries(tokenized)
				.map(([k]) => [k, []])
				.concat(invalids)
				.reduce((p, [k, v]) => {
					p[k] = p[k] || []
					p[k] = p[k].concat(v)
					return p
				}, {}),
		)
	} else {
		return Valid.Y(tokenized)
	}
}

const formatArgs = args =>
	args
		? args.startsWith('/')
			? formatArgs(args.slice(1))
			: args.endsWith('/')
			? formatArgs(args.slice(0, -1))
			: args
		: ''

function SafeRouteType({ typeName, tokenized }) {
	let $
	$ = Object.entries(tokenized).map(([caseName, tokens]) => {
		const keys = tokens.value
			.reduce(
				(p, n) =>
					PatternToken.fold({
						Path: () => p,
						Part: key => p.concat(key),
					})(n),
				[],
			)
			.sort()

		function of(theirO) {
			const { args = '', ...o } = theirO || {}
			const foundKeys = Object.keys(o).sort()

			if (foundKeys.join('|') !== keys.join('|')) {
				return Valid.N(
					new TypeError(
						'Property mismatch for ' +
							typeName +
							'.' +
							caseName +
							'.  Expected: {' +
							keys.join(',') +
							'}' +
							' but found: {' +
							foundKeys.join() +
							'}',
					),
				)
			} else {
				return Valid.Y({
					type: typeName,
					case: caseName,
					tag: caseName,
					value: { ...o, args: formatArgs(args) },
				})
			}
		}

		return {
			[caseName]: of,
		}
	})

	$ = $.reduce((p, n) => Object.assign(p, n), {})

	return $
}

function RouteType({ typeName, safeRouteType }) {
	let $
	$ = Object.entries(safeRouteType).map(([key, of]) => ({
		[key]: o =>
			Valid.fold({
				Y: x => x,
				N(err) {
					throw err
				},
			})(of(o)),
	}))

	$ = $.reduce((p, n) => Object.assign(p, n))
	$ = { ...$, type: typeName, specs: {}, tags: Object.keys(safeRouteType) }
	$ = S.decorate($)

	return $
}

const PatternMatches = ({ tokenized, url }) => {
	const pairs = Object.entries(tokenized).map(([key, patternTokens]) => [
		key,
		tokenizeURL(patternTokens.value, url),
	])

	const invalid = pairs
		.filter(([, valid]) => valid.tag === 'N')
		.map(([key, { value }]) => ({ [key]: value }))
		.reduce((p, n) => Object.assign(p, n), {})

	const valid = pairs
		.filter(([, valid]) => valid.tag === 'Y')
		.sort(
			([, { value: a }], [, { value: b }]) =>
				PatternToken.groupSpecificity(b) - PatternToken.groupSpecificity(a),
		)

	if (valid.length) {
		return Valid.Y(valid)
	} else {
		return Valid.N(invalid)
	}
}

const Matches = ({ routeType }) => patternMatches => {
	return Valid.bifold(Valid.N, xs =>
		// xs being non empty is technically a precondition
		// to being marked as valid
		// but we check it in any case as the types make it possible
		xs.length == 0
			? /* istanbul ignore next */
			  Valid.N({})
			: Valid.Y(
					xs.map(([caseName, { value }]) =>
						routeType[caseName](URLToken.toArgs(value)),
					),
			  ),
	)(patternMatches)
}

function type$safe(typeName, cases) {
	// StrMap (CaseName, Valid( N::PatternToken.Error[] | Y::PatternToken[] ) )
	const tokenized = Object.entries(cases)
		.map(([caseName, pattern]) => {
			return { [caseName]: tokenizePattern(pattern.replace(/\/$/, '')) }
		})
		.reduce((p, n) => Object.assign(p, n), {})

	const validated = routeValidator({ tokenized })

	if (validated.tag === 'N') {
		return validated
	} else {
		const safeRouteType = SafeRouteType({ typeName, tokenized })

		const routeType = RouteType({ typeName, safeRouteType })

		const matches = url => {
			const patternMatches = PatternMatches({ tokenized, url })
			return Matches({ routeType })(patternMatches)
		}

		const matchOr = (otherwise, url) => {
			const match = [url]
				.map(matches)
				.flatMap(Valid.bifold(otherwise, x => x))
				.slice(0, 1)

			return match.length > 0
				? match[0]
				: /* istanbul ignore next */
				  otherwise({})
		}

		const toURL = routeCase => {
			const raw =
				'/' +
				tokenized[routeCase.tag].value
					.map(
						PatternToken.fold({
							Part: key => routeCase.value[key],
							Path: key => key,
						}),
					)
					.concat(formatArgs(routeCase.value.args))
					.join('/')

			return raw.replace(/\/$/, '').replace('//', '/')
		}

		return Valid.Y(
			Object.assign(
				{ safe: safeRouteType, matches, matchOr, toURL },
				routeType,
			),
		)
	}
}

const type = (typename, cases) =>
	Valid.bifold(
		errs => {
			throw Object.values(errs)
				.flatMap(xs => xs)
				.map(x => x.value)
				.shift()
		},
		x => x,
	)(type$safe(typename, cases))

export default {
	tokenizePattern,
	tokenizeURL,
	PatternToken,
	URLToken,
	Valid,
	type$safe,
	type,
}
