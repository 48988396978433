import Dexie from 'dexie'
import h from './hyperscript'
import stream from './mithril/stream/stream.js'
import * as R from 'ramda'

export default function DB(versionNumber, definitions) {
	function instance({ dexieDBInstance: db }) {
		let requests = stream()

		function Loader() {
			let loading = 0

			let afters = []

			async function load(f) {
				loading++
				await f()
				loading--
				if (loading == 0) {
					for (let f of afters) {
						f()
					}
				}

				h.redraw()
			}

			function after(f) {
				afters.push(f)
			}

			async function loadModel(table, key, defaultValue, prop) {
				await load(async () => {
					let existing = (await get(table, key)) || defaultValue

					await put(table, existing)

					prop(x => ({ ...x, ...existing }))

					let skip = true
					prop.$stream().map(async x => {
						if (!skip) {
							await put(table, x, key)
						}
						skip = false
					})
				})
			}

			async function loadCollection(
				table,
				keyFn,
				prop,
				seedCollection = [],
				visitor = x => x,
			) {
				await load(async () => {
					let existing = await toArray(table)

					let idx = R.groupBy(keyFn, seedCollection.concat(existing))
					let merged = Object.values(idx).flatMap(xs => visitor(R.mergeAll(xs)))

					await bulkPut(table, merged)

					prop(merged)

					let skip = true
					prop.$stream().map(xs => {
						if (!skip) {
							db.bulkPut(table, xs)
						}
						skip = false
					})
				})
			}

			return {
				load,
				loadModel,
				loadCollection,
				get loading() {
					return loading
				},
				after,
			}
		}

		let listeners = {}

		async function listen(table, callback) {}

		function request(table, key) {
			throw new Error('Not implemented')
		}

		async function get(table, key) {
			requests({ fn: 'get', table, key })
			return db[table].get(key).catch(function (error) {
				console.error(error)
				return request(table, key)
			})
		}
		async function put(table, data, key) {
			requests({ fn: 'put', table, key, data })
			return db[table].put(data, key)
		}
		async function toArray(table, where) {
			requests({ fn: 'toArray', table })
			let $ = db[table]
			if (where) {
				$ = $.where(where)
			}
			return $.toArray()
		}
		async function bulkPut(table, xs) {
			requests({ fn: 'bulkPut', table, data: xs })
			return db[table].bulkPut(xs)
		}

		async function transaction(tables, f) {
			return db.transaction(
				'rw',
				tables.map(table => db[table]),
				f,
			)
		}

		return { requests, Loader, bulkPut, toArray, put, get, listen, transaction }
	}

	let dexieDBInstance = new Dexie('butedb')

	dexieDBInstance.version(versionNumber).stores(definitions)

	return instance({ dexieDBInstance })
}
