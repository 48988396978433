'use strict'

import buildQueryString from '../querystring/build'
const assign = Object.assign

// Returns `path` from `template` + `params`
export default function (template, params) {
	if (/:([^\/\.-]+)(\.{3})?:/.test(template)) {
		throw new SyntaxError(
			"Template parameter names must be separated by either a '/', '-', or '.'.",
		)
	}
	if (params == null) return template
	let queryIndex = template.indexOf('?')
	let hashIndex = template.indexOf('#')
	let queryEnd = hashIndex < 0 ? template.length : hashIndex
	let pathEnd = queryIndex < 0 ? queryEnd : queryIndex
	let path = template.slice(0, pathEnd)
	let query = {}

	assign(query, params)

	let resolved = path.replace(/:([^\/\.-]+)(\.{3})?/g, function (
		m,
		key,
		variadic,
	) {
		delete query[key]
		// If no such parameter exists, don't interpolate it.
		if (params[key] == null) return m
		// Escape normal parameters, but not variadic ones.
		return variadic ? params[key] : encodeURIComponent(String(params[key]))
	})

	// In case the template substitution adds new query/hash parameters.
	let newQueryIndex = resolved.indexOf('?')
	let newHashIndex = resolved.indexOf('#')
	let newQueryEnd = newHashIndex < 0 ? resolved.length : newHashIndex
	let newPathEnd = newQueryIndex < 0 ? newQueryEnd : newQueryIndex
	let result = resolved.slice(0, newPathEnd)

	if (queryIndex >= 0) result += template.slice(queryIndex, queryEnd)
	if (newQueryIndex >= 0)
		result +=
			(queryIndex < 0 ? '?' : '&') + resolved.slice(newQueryIndex, newQueryEnd)
	let querystring = buildQueryString(query)
	if (querystring)
		result += (queryIndex < 0 && newQueryIndex < 0 ? '?' : '&') + querystring
	if (hashIndex >= 0) result += template.slice(hashIndex)
	if (newHashIndex >= 0)
		result += (hashIndex < 0 ? '' : '&') + resolved.slice(newHashIndex)
	return result
}
