'use strict'

import Vnode from '../render/vnode'
import hyperscriptVnode from './hyperscriptVnode'
import hasOwn from '../util/hasOwn'

let selectorParser = /(?:(^|#|\.)([^#\.\[\]]+))|(\[(.+?)(?:\s*=\s*("|'|)((?:\\["'\]]|.)*?)\5)?\])/g
let selectorCache = {}

function isEmpty(object) {
	for (let key in object) if (hasOwn.call(object, key)) return false
	return true
}

function compileSelector(selector) {
	let match,
		tag = 'div',
		classes = [],
		attrs = {}
	while ((match = selectorParser.exec(selector))) {
		let type = match[1],
			value = match[2]
		if (type === '' && value !== '') tag = value
		else if (type === '#') attrs.id = value
		else if (type === '.') classes.push(value)
		else if (match[3][0] === '[') {
			let attrValue = match[6]
			if (attrValue)
				attrValue = attrValue.replace(/\\(["'])/g, '$1').replace(/\\\\/g, '\\')
			if (match[4] === 'class') classes.push(attrValue)
			else attrs[match[4]] = attrValue === '' ? attrValue : attrValue || true
		}
	}
	if (classes.length > 0) attrs.className = classes.join(' ')
	return (selectorCache[selector] = { tag: tag, attrs: attrs })
}

function execSelector(state, vnode) {
	let attrs = vnode.attrs
	let children = Vnode.normalizeChildren(vnode.children)
	let hasClass = hasOwn.call(attrs, 'class')
	let className = hasClass ? attrs.class : attrs.className

	vnode.tag = state.tag
	vnode.attrs = null
	vnode.children = undefined

	if (!isEmpty(state.attrs) && !isEmpty(attrs)) {
		let newAttrs = {}

		for (var key in attrs) {
			if (hasOwn.call(attrs, key)) newAttrs[key] = attrs[key]
		}

		attrs = newAttrs
	}

	for (var key in state.attrs) {
		if (
			hasOwn.call(state.attrs, key) &&
			key !== 'className' &&
			!hasOwn.call(attrs, key)
		) {
			attrs[key] = state.attrs[key]
		}
	}
	if (className != null || state.attrs.className != null)
		attrs.className =
			className != null
				? state.attrs.className != null
					? String(state.attrs.className) + ' ' + String(className)
					: className
				: state.attrs.className != null
				? state.attrs.className
				: null

	if (hasClass) attrs.class = null

	for (var key in attrs) {
		if (hasOwn.call(attrs, key) && key !== 'key') {
			vnode.attrs = attrs
			break
		}
	}

	if (
		Array.isArray(children) &&
		children.length === 1 &&
		children[0] != null &&
		children[0].tag === '#'
	) {
		vnode.text = children[0].children
	} else {
		vnode.children = children
	}

	return vnode
}

function hyperscript(selector) {
	if (
		selector == null ||
		(typeof selector !== 'string' &&
			typeof selector !== 'function' &&
			typeof selector.view !== 'function')
	) {
		throw Error('The selector must be either a string or a component.')
	}

	let vnode = hyperscriptVnode.apply(1, arguments)

	if (typeof selector === 'string') {
		vnode.children = Vnode.normalizeChildren(vnode.children)
		if (selector !== '[')
			return execSelector(
				selectorCache[selector] || compileSelector(selector),
				vnode,
			)
	}

	vnode.tag = selector
	return vnode
}

export default hyperscript
