import m from './mithril/index'
import * as z from './z/index.js'
import stream from './mithril/stream/stream'

function collectBindings(args) {
	let bindings = []
	for (let i = 1; i < args.length; i++) {
		let arg = args[i]

		if (arg == null) {
			continue
		}

		if (z.isZ2Proxy(arg)) {
			args[i] = null
			bindings.push({ i, type: 'z2', value: arg })
		} else if (arg.constructor == stream) {
			args[i] = null
			bindings.push({ i, type: 'stream', value: arg })
		}
	}
	return bindings
}

function activateBindings({ bindings, vnode }) {
	if (!vnode.attrs) {
		return null
	}

	// Bind stream/z children to m.render on change
	if (bindings.length) {
		let oldcreate, oldremove
		if (vnode.attrs.oncreate) {
			oldcreate = vnode.attrs.oncreate
		}

		if (vnode.attrs.onremove) {
			oldremove = vnode.attrs.onremove
		}

		vnode.attrs.oncreate = vnode => {
			oldcreate && oldcreate(vnode)
			for (let binding of bindings) {
				if (binding.type == 'z2') {
					binding.stream = binding.value.$stream().map(x => {
						h.render(vnode.dom, x)
					})
				} else if (binding.type == 'stream') {
					binding.stream = binding.value.map(x => {
						h.render(vnode.dom, x)
					})
				}
			}
		}
		vnode.attrs.onbeforeremove = vnode => {
			oldremove && oldremove(vnode)
			for (let binding of bindings) {
				binding.stream.end(true)
			}
		}
	}
}

function preventAutoRedraw(vnode) {
	vnode.attrs &&
		Object.entries(vnode.attrs)
			.filter(
				([k]) =>
					k.startsWith('on') &&
					!(
						k in
						{
							oncreate: 1,
							onremove: 1,
							onupdate: 1,
							onbeforeupdate: 1,
							onbeforeremove: 1,
							oninit: 1,
						}
					),
			)
			.forEach(([k, v]) => {
				vnode.attrs[k] = function (...args) {
					args[0].redraw = false
					v.apply(this, args)
				}
			})
}

export default function h(...args) {
	const bindings = collectBindings(args)

	const vnode = m(...args)

	activateBindings({ vnode, bindings })

	// Prevent auto redraw
	preventAutoRedraw(vnode)

	return vnode
}

h.trust = m.trust
h.fragment = m.fragment
h.mount = m.mount
h.redraw = m.redraw
h.render = m.render
h.request = m.request
